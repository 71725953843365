import React, { useState, useEffect } from 'react';
import { fetchContactus } from '../apis/content/ContentApi';
import DOMPurify from 'dompurify';

function Contactus() {
  const [contactData, setContactData] = useState(''); 
  const sanitizedContactData = DOMPurify.sanitize(contactData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchContactus({ content: "string" });
        if (response.data) {
          setContactData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className='' style={{minHeight:'50vh'}}>
      {contactData && (
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedContactData  }}
        />
      )}
    </div>
  );
}

export default Contactus;
