import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { getCategory } from '../../apis/category/CategoryApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


function QuoteCard({ category }) {
  return (
    <div className={`card bg-light text-black rounded-3 mb-3 `} >
      <div className="card-body p-1 hover-card" style={{ transition: "background-color 0.3s", display: 'flex', justifyContent: 'center' }}>
        <figure className="mb-0">
          <figcaption className="blockquote  mt-3"> 
            <Link to={`/categories/${category.id}`}>{category.category_name}</Link> 
          </figcaption>  
        </figure>
      </div>
    </div>
  );
}


function Categories() {
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const createCategories = async () => {
      try {
       const response = await getCategory({
        limit: 10,
        page: 1,
        search: searchQuery,
       });

        if (response.data && response.data.data) {
          setCategories(response.data.data.rows);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    createCategories();
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <section>
    
    <div className="search-container mt-5 mb-4 d-flex justify-content-center ">
      <div style={{ position: 'relative', display: 'inline-block'}}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search Here"
          className="form-control"
          style={{ textAlign: 'center', borderRadius: '20px' }}
        />
  <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'grey' }} />
</div>
</div>
      <section className="d-flex justify-content-center align-items-center">
        <div className="row d-flex justify-content-center align-items-center">
          {categories.map((category) => (
            <div key={category.id} className="col-md-5 mb-5 card-max ">
              <QuoteCard category={category} />
            </div>
          ))}
        </div>
      </section>
      </section>
  );
}

export default Categories;
