import axios from "axios";
import { API_URL, SECRET_KEY } from "../../config/Constants";
export const getAuthors = async(data)=>{
  
return   await axios.post(
    `${API_URL}/author/fetch-authors`,
     data,
    {
      headers: {
        "x-secret-key":
         `${SECRET_KEY}`,
      },
    }
  );
} 
export const getAuthorById = async(data)=>{
    return await axios.post(
        `${API_URL}/author/fetch-author-by-id`,
         data,
        {
          headers: {
            "x-secret-key":
            `${SECRET_KEY}`,
          },
        }
      );
}