import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { getAuthors } from '../../apis/authors/AuthorsApi';
import './authors.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


function QuoteCard({ author, designation, count }) {
  return (
    <div className={`card text-black rounded-3 mb-3`} style={{  background: "linear-gradient(to bottom, #e6f2ff 0%, #ffffff 100%)" }}>
      <div className="card-body d-flex flex-column justify-content-between p-3">
        <div>
          <figure className="mb-0">
            <figcaption className="blockquote text-black">
              <Link to={`/authors/${author.id}`}>{author.author_name}</Link>
            </figcaption>
            <blockquote>
              <p className="pb-0 mb-1" style={{ fontSize: '0.9rem' }}>{designation}</p>
            </blockquote>
          </figure>
        </div>
        <div className="text-end">
          <span className="badge bg-secondary">{count} Quotes</span>
        </div>
      </div>
    </div>
  );
}

function Authors() {
  const [authors, setAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const createAuthors = async () => {
      try {
        const response = await getAuthors({
          limit: 10,
          page: 1,
          search: searchQuery, 
        });

        if (response.data && response.data.data) {
          setAuthors(response.data.data.rows);
        }
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    createAuthors();
  }, [searchQuery]); 

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); 
  };

  return (
    <section>
    {/* <h1 className="text-center mt-5 mb-4">Famous Authors</h1> */}
    <div className="search-container mt-5 mb-4 d-flex justify-content-center">
      <div style={{ position: 'relative', display: 'inline-block'}}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search Here"
          className="form-control"
          style={{ textAlign: 'center', borderRadius: '20px' }}
        />
        <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'grey' }} />
      </div>
    </div>
    <div className="d-flex justify-content-center align-items-center ">
      <div className="container row d-flex justify-content-center align-items-center">
        {authors.map((author) => (
          <div key={author.id} className="col-md-5 card-g">
            <QuoteCard
              author={author}
              designation={author.designation}
              count={author.quote_count}
            />
          </div>
        ))}
      </div>
    </div>
  </section>
  

  );
}

export default Authors;
