import axios from "axios";
import { API_URL, SECRET_KEY } from "../../config/Constants";

export const getCategoryById = async (data) => {
  return await axios.post(
    `${API_URL}/quotes-category/fetch-quotes-category-by-id`,
    data,
    {
      headers: {
        "x-secret-key":
          `${SECRET_KEY}`,
      },
    }
  );
};
export const getCategory = async (data)=>{
   return await axios.post(
        `${API_URL}/quotes-category/fetch-quotes-categories`,
        data,
        {
          headers: {
            "x-secret-key":
            `${SECRET_KEY}`,
          },
        }
      );
    
}

