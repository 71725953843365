import axios from "axios";
export const fetchQuoteOfTheDay = async (data) =>{
    return await axios.post(
        'https://api.weinspire.ilprodserver.cloud/api/quote/fetch-quote-of-the-day', 
         data,
        {
          headers: {
            'x-secret-key': 'BBBBHphQTwvSgVoBxfKhoj5PJexrkWi3sJfBzQdybKiQmsIksSV2GIZWO5N3L6k3DFCq5X',
          },
        }
      );
}
export const fetchTopQuotes =async (data)=>{
    return  await axios.post(
        'https://api.weinspire.ilprodserver.cloud/api/quote/fetch-top-quotes',
         data,
        {
          headers: {
            'x-secret-key': 'BBBBHphQTwvSgVoBxfKhoj5PJexrkWi3sJfBzQdybKiQmsIksSV2GIZWO5N3L6k3DFCq5X',
          },
        }
      );
}