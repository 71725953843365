import React, { useState, useEffect } from 'react';
import { fetchPrivacyPolicy } from '../apis/content/ContentApi';
import DOMPurify from 'dompurify';

function PrivacyPolicy() {
  const [policyData, setPolicyData] = useState(''); 
  const sanitizedPolicyData = DOMPurify.sanitize(policyData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPrivacyPolicy({ content: "string" });
        if (response.data) {
          setPolicyData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className='container'>
      {policyData && (
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedPolicyData  }}
        />
      )}
    </div>
  );
}

export default PrivacyPolicy;
