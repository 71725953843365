import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { nav } from '../../data/Data';
import './navbar.css';

const Navbar = () => {
  const [navlist, setNavList] = useState(false);
  const [activeTab, setActiveTab] = useState(null); // State to track active tab

  const handleListClick = (index) => {
    setNavList(false);
    setActiveTab(index); // Set active tab when a tab is clicked
  };

  return (
    <header className="navbar">
      <div className='container'>
        <div className="logo">
          <Link to="/">
            <img src="../images/landing_app_logo.png" alt="" />
          </Link>
        </div>
        <div className='nav'>
          <ul className={navlist ? 'nav-list small' : 'nav-list flex'}>
            {nav.map((list, index) => (
              <li key={index} onClick={() => handleListClick(index)}>
                <Link to={list.path} className={activeTab === index ? 'active' : ''}>{list.text}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="toggle">
          <button onClick={() => setNavList(!navlist)}>
            {navlist ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
          </button>
        </div>
      </div>
    </header>
  )
}

export default Navbar;
