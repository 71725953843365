import React, { useState, useEffect } from 'react';
import { fetchAboutUs } from '../apis/content/ContentApi';
import DOMPurify from 'dompurify';

function AboutUs() {
  const [aboutData, setAboutData] = useState([]); 
  const sanitizedAboutData = DOMPurify.sanitize(aboutData);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
       
        const response = await fetchAboutUs({content : "string"});
        if (response.data ) {
        setAboutData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
      }, []); 

  return (
    
    <div className='container'>
    {aboutData && (
      <div
        dangerouslySetInnerHTML={{ __html: sanitizedAboutData }}
      />
    )}
  </div>
  );
}

export default AboutUs;

