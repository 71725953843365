import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { getAuthorById } from '../../apis/authors/AuthorsApi';
import './authors.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function AuthorsById() {
  const [quotes, setQuotes] = useState([]);
  const { id } = useParams();
  const [copied, setCopied] = useState(false);
  
  useEffect(() => {
    const createQuote = async () => {
      try {
        const response = await getAuthorById({
          limit: 10, 
          page: 1,
          author_id: id,
        });
        if (response.data && response.data.data && response.data.data.quotes.length > 0) {
          setQuotes(response.data.data.quotes);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };
    createQuote();
  }, [id]); 

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const share = async (quote) => {
    try {
      await navigator.share({
        title: 'Share Quote',
        text: quote.quote_text,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <section className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFE7C7' }}>
      <div className=" py-5"> 
        <div className="row justify-content-center">
          {quotes.map((quote, index) => (
            <div key={index} className="col-md-6 mb-4 d-flex justify-content-center">
              <div className="text-center bg-white p-4 shadow rounded card-t">
                <i className="far fa-gem fa-lg mb-3" style={{ color: '#f9a169' }}></i>
                <blockquote className="blockquote pb-2">
                  <p>
                    <span className="lead font-italic">{quote.quote_text}</span>
                  </p>
                </blockquote>
                <div className="ms-2">
                <button className="btn" onClick={() => share(quote)}>
                <FontAwesomeIcon icon={faShareAlt} className="me-2 " style={{ color: "grey" }} />
                </button>
                <CopyToClipboard text={quote.quote_text} onCopy={handleCopy}>
                        <button className="btn">
                          <FontAwesomeIcon icon={faCopy} style={{color:'grey'}} />
                        </button>
                      </CopyToClipboard>
                      {copied && <span className="ms-2 text-grey">Copied!</span>}
              </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AuthorsById;
