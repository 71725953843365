import  QuoteOfTheDay from './QuoteOfTheDay';
import LatestQuotes from './LatestQuotes';





function Home() {
  return (
    <>
   
    <QuoteOfTheDay/>
    <LatestQuotes/>
    
    
  </>
  );
}

export default Home;
