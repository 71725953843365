import axios from "axios";
import { API_URL } from "../../config/Constants";

export const fetchAboutUs = async(data) =>{
    return await axios.get(
        `${API_URL}/common/page-content/get-page-content-by-type?type=About_Us`,
        data
    )
}
export const fetchPrivacyPolicy = async(data)=>{
    return await axios.get(
        `${API_URL}/common/page-content/get-page-content-by-type?type=Privacy_Policy`,
        data,
    )
}
export const fetchContactus =async (data)=>{
    return await axios.get(
        `${API_URL}/common/page-content/get-page-content-by-type?type=Contact_Us`,
        data,
    )
}