import React, { useState, useEffect } from 'react';
import { fetchQuoteOfTheDay } from '../apis/quote/QuoteApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function QuoteOfTheDay() {
  const [quote, setQuote] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await fetchQuoteOfTheDay();
        if (response.data && response.data.data) {
          setQuote(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };

    fetchQuote();
  }, []);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const share = async (quote) => {
    try {
      await navigator.share({
        title: 'Share Quote',
        text: quote.quote_text,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <section className="vh-50 d-flex align-items-center justify-content-center" style={{ background: 'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%)', paddingTop: '20px',paddingBottom:'20px' }}>
  <div className="">
    <div className="row justify-content-center">
      {quote && (
        <div className="col-lg-15">
          <div className="card text-center position-relative" style={{ maxWidth: '400px', margin: '0 auto', marginInline: '10px', marginInlineSm: '0' }}>
            <div className="card-body p-3">
              <div className="mb-3">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-quotes/bulb.webp" alt="Bulb" className="img-fluid" style={{ maxWidth: '100px' }} />
              </div>
              <blockquote className="blockquote">
                <p className="pb-2">
                  <i className="fas fa-quote-left fa-xs text-primary me-2"></i>
                  <span className="lead font-italic">{quote.quote_text}</span>
                  <i className="fas fa-quote-right fa-xs text-primary ms-2"></i>
                </p>
              </blockquote>
              <figcaption className="blockquote-footer mb-0">
                {quote.author.author_name}
              </figcaption>
              <div className="position-absolute top-0 end-0 p-2"> 
              <button className="btn" onClick={() => share(quote)}>
                <FontAwesomeIcon icon={faShareAlt} className="me-2 " style={{ color: "grey" }}/>
                </button>
               
                <CopyToClipboard text={quote.quote_text} onCopy={handleCopy}>
                        <button className="btn">
                          <FontAwesomeIcon icon={faCopy} style={{color:'grey'}} />
                        </button>
                      </CopyToClipboard>
                      {copied && <span className=" text-grey">Copied!</span>}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
</section>

  
  
  );
}

export default QuoteOfTheDay;
