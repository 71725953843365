import Home from "./components/Home";
import Navbar from "./components/navbar/Navbar";
import Authors from "./components/authors/Authors";
import Categories from "./components/categories/Categories";
import QuoteOfTheDay from "./components/QuoteOfTheDay";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Aboutus from "./components/Aboutus";
import Quotes from "./components/authors/AuthorsById";
import CategoriesById from "./components/categories/CategoriesById";
import ScrolltoTop from "./components/scrollup/ScolltoTop";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contactus from "./components/Contactus";

function App() {
  return (
    <>
      <Router>
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/authors" element={<Authors />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/quoteoftheday" element={<QuoteOfTheDay />} />
            <Route path="/authors/:id" element={<Quotes />} />
            <Route path="/categories/:id" element={<CategoriesById />} />
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/contactus" element={<Contactus/>}/>
            
          </Routes>
          <ScrolltoTop />
          <Footer />
        </>
      </Router>
    </>
  );
}

export default App;
