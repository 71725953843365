import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchTopQuotes } from '../apis/quote/QuoteApi';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function LatestQuotes() {
  const [quotes, setQuotes] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await fetchTopQuotes({
          search: '',
          page: 1,
          limit: 10,
        });
        if (response.data && response.data.data) {
          setQuotes(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching authors:', error);
      }
    };

    fetchQuotes();
  }, []); 

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const share = async (quote) => {
    try {
      await navigator.share({
        title: 'Share Quote',
        text: quote.quote_text,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div>
      <h1 style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '5%', maxWidth: '80%' }}>Latest Quotes</h1>

      <section className="vh-80 bg-light">
        <div className="container py-5 h-100">
          <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center align-items-center">
            {quotes.map((quote) => (
              <div key={quote.id} className="col">
                <MDBCard className="text-white position-relative" style={{ backgroundColor: '#1f959b', borderRadius: '15px' }}>
                  <MDBCardBody className="p-5 d-flex flex-column justify-content-between" style={{ height: '350px' }}>
                    <div>
                      <i className="fas fa-quote-left fa-2x mb-4"></i>
                      <p className="lead">{quote.quote_text}</p>
                      <hr />
                      <h6 className="mb-0">      
                        <span className=''>--{quote.author.author_name}</span>
                      </h6>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <button className="btn  me-3" onClick={() => share(quote)}> 
                        <FontAwesomeIcon icon={faShareAlt} style={{color:'white'}} />
                      </button>
                      <CopyToClipboard text={quote.quote_text} onCopy={handleCopy}>
                        <button className="btn">
                          <FontAwesomeIcon icon={faCopy} style={{color:'white'}} />
                        </button>
                      </CopyToClipboard>
                      {copied && <span className="ms-2 text-white">Copied!</span>}
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default LatestQuotes;
