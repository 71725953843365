export const nav = [
    {
        text: "Home",
        path: "/",
    },
    {
        text: "Authors",
        path: "/authors",
    },
    {
        text: "Categories",
        path: "/categories",
    },
    {
        text: "Quote of the Day",
        path: "/quoteoftheday"
    },
  
]
export const footer =[
    {
        text: "Privacy Policy",
        path: "/privacy"
    },
    {
        text: "About Us",
        path: "/aboutus"
    },
    {
        text:"Contact Us",
        path: "/contactus"
    }
]